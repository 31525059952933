/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-04-02",
    versionChannel: "nightly",
    buildDate: "2025-04-02T00:06:54.498Z",
    commitHash: "9814df80491543c6ada0f5f2a6f8358ca7b7c120",
};
