/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-04-07",
    versionChannel: "nightly",
    buildDate: "2025-04-07T00:07:02.687Z",
    commitHash: "65753938ca5d56b4679d7bc7170da50ef52684bb",
};
