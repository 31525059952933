/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-04-08",
    versionChannel: "nightly",
    buildDate: "2025-04-08T00:06:49.149Z",
    commitHash: "3dfca4f0edc9e7110b858975448138c7ee1ee7e0",
};
