/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-04-09",
    versionChannel: "nightly",
    buildDate: "2025-04-09T00:06:52.654Z",
    commitHash: "e58f3b7594d72e60169515690f04b65a7b1a5549",
};
